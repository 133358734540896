import { serverUrl, wrapRootElement as wrap } from './wrap-root-element'

export const wrapRootElement = wrap

// Analytics
let skip = process.env.NODE_ENV !== 'production'
let sessionEvents = []

export const onRouteUpdate = ({ location }) => {
  sessionEvents.push({
    timeStamp: new Date(),
    event: location.pathname,
    label: 'PAGE',
  })
}

window.addEventListener('pagehide', endSession)
window.addEventListener('beforeunload', endSession)
window.addEventListener('unload', endSession)
window.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') return endSession()
  newSession()
})

const newSession = () => {
  sessionEvents = [
    {
      timeStamp: new Date(),
      event: window.location.pathname,
      label: 'PAGE',
    },
  ]
  skip = false
}

const endSession = () => {
  if (skip) return
  skip = true

  sessionEvents.push({
    timeStamp: new Date(),
    event: 'End Session',
    label: 'EXIT',
  })

  const [p] = performance.getEntriesByType('navigation')
  const data = {
    referrer: document.referrer,
    userAgent: navigator.userAgent,
    language: navigator.language,
    latency: p.duration,
    pageLoad: p.loadEventEnd - p.loadEventStart,
    sessionEvents,
  }

  window.navigator.sendBeacon(serverUrl + '/sessions', JSON.stringify(data))
}
