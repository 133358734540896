import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Code } from './src/components/code'
import { preToCodeBlock } from 'mdx-utils'
import { RecoilRoot } from 'recoil'
import feathersAuth from '@feathersjs/authentication-client'
import feathersClient from '@feathersjs/feathers'
import feathersSocket from '@feathersjs/socketio-client'
import * as io from 'socket.io-clientv2'
import { Provider as FigbirdProvider } from 'figbird'
import Auth from './src/components/Auth/Auth'
import "katex/dist/katex.min.css"

export const serverUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://blog-api.aaronolsen.ca'
    : 'http://localhost:3030'

// components is its own object outside of render so that the references to
// components are stable
const components = {
  pre: preProps => {
    const props = preToCodeBlock(preProps)
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />
    } else {
      // it's possible to have a pre without a code in it
      return <pre {...preProps} />
    }
  },
}

const client = feathersClient()
if (typeof window !== 'undefined') {
  const socket = io.connect(serverUrl)
  client.configure(feathersSocket(socket))
  client.configure(
    feathersAuth({ jwtStrategy: 'jwt', storage: window.localStorage })
  )
}

export const wrapRootElement = ({ element }) => (
  <FigbirdProvider feathers={client}>
    <RecoilRoot>
      <Auth>
        <MDXProvider components={components}>{element}</MDXProvider>
      </Auth>
    </RecoilRoot>
  </FigbirdProvider>
)
