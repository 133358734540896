import { useFeathers } from "figbird"
import React from 'react'
import userState from './userState'
import { useSetRecoilState } from "recoil"

const Auth = ({ children }) => {
  const feathers = useFeathers();
  const setUser = useSetRecoilState(userState);

  React.useEffect(() => {
    feathers
      .reAuthenticate(false, "jwt")
      .then((res) => setUser(res.user))
      .catch((e) => {
        localStorage.removeItem("feathers-jwt");
      });
  }, [setUser, feathers]);

  return children
}

export default Auth