import React from 'react'
import GraphSlider from './GraphSlider'
import TeX from '@matejmazur/react-katex'
import FunctionPlot, { add, brack } from './FunctionPlot'

const InteractiveGraph = ({ advanced }) => {
  const [func, setFunc] = React.useState(advanced ? 'degree 2' : 'simple')

  const [a, setA] = React.useState(1)
  const [k, setK] = React.useState(1)
  const [c, setC] = React.useState(0)
  const [d, setD] = React.useState(0)

  const [normalize, setNormalize] = React.useState(!advanced)

  const resetAll = () => {
    setA(1)
    setK(1)
    setC(0)
    setD(0)
  }

  let equation;
  let fn;
  switch (func) {
    case 'simple':
      equation = `y = ${a}(${k}(x - ${brack(d)}))^2 ${add(c)}`
      fn = `${a} * (${k} * (x - ${d}))^2 + ${c}`
      break;
    case 'linear':
      equation = `\\frac{y ${add(c)}}{${a}} = \\frac{x ${add(d)}}{${k}}`
      fn = `${a} * ((x + ${d}) / ${k}) - ${c}`
      break;
    case 'degree 2':
      equation = `\\frac{y ${add(c)}}{${a}} = \\left(\\frac{x ${add(d)}}{${k}}\\right)^2`
      fn = `${a} * ((x + ${d}) / ${k})^2 - ${c}`
      break;
    case 'degree 3':
      equation = `\\frac{y ${add(c)}}{${a}} = \\left(\\frac{x ${add(d)}}{${k}}\\right)^3`
      fn = `${a} * ((x + ${d}) / ${k})^3 - ${c}`
      break;
    case 'sine':
      equation = `\\frac{y ${add(c)}}{${a}} = \\sin\\left(\\frac{x ${add(d)}}{${k}}\\right)`
      fn = `${a} * sin((x + ${d}) / ${k}) - ${c}`
      break;
    case 'cosine':
      equation = `\\frac{y ${add(c)}}{${a}} = \\cos\\left(\\frac{x ${add(d)}}{${k}}\\right)`
      fn = `${a} * cos((x + ${d}) / ${k}) - ${c}`
      break;
    case 'tangent':
      equation = `\\frac{y ${add(c)}}{${a}} = \\tan\\left(\\frac{x ${add(d)}}{${k}}\\right)`
      fn = `${a} * tan((x + ${d}) / ${k}) - ${c}`
      break;
    case 'root':
      equation = `\\frac{y ${add(c)}}{${a}} = \\sqrt{\\frac{x ${add(d)}}{${k}}}`
      fn = `${a} * sqrt((x + ${d}) / ${k}) - ${c}`
      break;
    case 'reciprocal':
      equation = `\\frac{y ${add(c)}}{${a}} = \\frac{${k}}{x ${add(d)}}`
      fn = `${a} * (${k} / (x + ${d})) - ${c}`
      break;
  }

  return <div>
    <TeX block>{equation}</TeX>
    <FunctionPlot options={{
      grid: true,
      yAxis: {
        domain: normalize
          ? [-5, 5]
          : [Math.abs(a) * (-5) - c, Math.abs(a) * 5 - c],
        invert: a < 0 && !normalize
      },
      xAxis:
      {
        domain: normalize
          ? [-7, 7]
          : [(-7 * Math.abs(k)) - d, (7 * Math.abs(k)) - d],
        invert: k < 0 && !normalize
      },
      disableZoom: true,
      data: [
        { fn, skipTip: true },
      ]
    }} />
    <GraphSlider name="a" value={a} onChange={setA} />
    <GraphSlider name="k" value={k} onChange={setK} />
    <GraphSlider name="c" value={c} onChange={setC} />
    <GraphSlider name="d" value={d} onChange={setD} />
    <div style={{
      display: 'flex',
      gap: '0.5em',
      flexWrap: 'wrap',
      flexDirection: 'row',
      marginTop: '0.5em',
      marginBottom: '3em',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <button onClick={resetAll}>Reset</button>
      {advanced && <>
        <label>Function
          <select value={func} onChange={e => setFunc(e.target.value)}>
            <option>linear</option>
            <option>degree 2</option>
            <option>degree 3</option>
            <option>root</option>
            <option>reciprocal</option>
            <option>sine</option>
            <option>cosine</option>
            <option>tangent</option>
          </select>
        </label>
        <label>
          Normalize graph
          <input
            type="checkbox"
            onChange={e => setNormalize(e.target.checked)}
            checked={normalize}
          />
        </label>
      </>
      }
    </div>
  </div>
}

export default InteractiveGraph