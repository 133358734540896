import React from "react"

import functionPlot from "function-plot/src"

export const add = x => x >= 0 ? `+${x}` : x

export const brack = x => x < 0 ? `(${x})` : x

const FunctionPlot = React.memo(({ options }) => {
  const rootEl = React.useRef(null)
  const [width, setWidth] = React.useState(500)

  React.useLayoutEffect(() => {
    const readWidth = () => setWidth(rootEl.current.offsetWidth)
    window.addEventListener("resize", readWidth)
    readWidth()
    return () => {
      window.removeEventListener("resize", readWidth)
    }
  }, [rootEl])

  React.useEffect(() => {
    try {
      functionPlot({ width, height: width * 5 / 7, ...options, target: rootEl.current })
    } catch (e) { }
  })

  return (<div ref={rootEl} />)
}, () => false)

export default FunctionPlot